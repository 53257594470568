<template>
  <div class="sales-category-setting">
    <PageTitle title="銷售類別設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <el-input v-model="nameSearch" clearable placeholder="輸入類別名稱" @keypress.enter.native="refresh(); tableOptions.page = 1;" @clear="refresh(); tableOptions.page = 1;">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(); tableOptions.page = 1;" />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table v-loading="loading" :data="salesCategoryList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column prop="name" label="名稱" align="center" />
        <el-table-column prop="SalesGroup" label="群組" align="center">
          <template slot-scope="scope">
            {{ (scope.row.SalesGroup && scope.row.SalesGroup.name) || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="order" label="排序" align="center" />
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), syncFormData(scope.row), selectRow=scope.row"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesCategoryCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <section>
        <el-form ref="form" :model="formData" label-position="top" :rules="formRules">
          <el-form-item label="名稱" prop="name">
            <el-input v-model="formData.name" placeholder="請輸入名稱" />
          </el-form-item>

          <el-form-item label="群組" prop="SalesGroupId">
            <el-select
              v-model="formData.SalesGroupId"
              clearable
              placeholder="請選擇群組"
            >
              <el-option
                v-for="item in salesGroupOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="排序" prop="order">
            <el-input v-model="formData.order" placeholder="請輸入順序" />
          </el-form-item>
        </el-form>
      </section>

      <div slot="footer">
        <el-button plain @click="showDialog = false, resetForm()">
          返回
        </el-button>
        <el-button type="primary" @click="dialogConfirm">儲存</el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteSalesCategory(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import {
  CreateSalesCategory,
  GetSalesCategory,
  GetSalesCategoryCount,
  FindSalesCategory,
  UpdateSalesCategory,
  DeleteSalesCategory,
  GetSalesGroup,
  GetSalesGroupCount,
} from '@/api/sales'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { getAllDataFromApi } from '@/utils/helper'

export default {
  name: 'SalesCategorySetting',
  components: { DeleteDialog, EmptyBlock },
  computed: {
    ...mapGetters([
      'shop',
    ]),

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增銷售類別',
          update: '編輯銷售類別',
        },
      )
    },

    salesGroupOptions () {
      return this.salesGroupList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
  },
  data: () => ({
    nameSearch: '',
    loading: false,
    // Dialog
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,
    selectRow: null,
    selectSalesCategory: null,

    salesCategoryCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    formData: {
      name: '',
      order: 100,
      salesProducts: [],
      SalesGroupId: '',
    },

    formRules: {
      name: noEmptyRules('請輸入類別名稱'),
      order: [isDigitRules(), noEmptyRules('請輸入排序'), rangeRules()],
    },

    salesCategoryList: [],
    salesGroupList: [],
  }),

  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getSalesCategoryGroup()
      await this.getSalesCategory()
      await this.getSalesCategoryCount()
      this.loading = false
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createSalesCategory()
      if (type === 'update') this.updateSalesCategory()
      this.showDialog = false
    },

    syncFormData (row) {
      this.formData = JSON.parse(JSON.stringify(row))
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        order: 100,
        salesProducts: [],
        SalesGroupId: '',
      }
    },

    //= > 創建銷售類別
    async createSalesCategory () {
      const form = this.formData
      try {
        await CreateSalesCategory({
          shopId: this.shop,
          name: form.name,
          order: form.order,
          salesProducts: form.salesProducts,
          SalesGroupId: form.SalesGroupId || null,
        })
        await this.refresh()
        this.$message.success('新增成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售類別
    async getSalesCategory () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      try {
        const res = await GetSalesCategory({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.salesCategoryList = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售類別總數
    async getSalesCategoryCount () {
      try {
        const res = await GetSalesCategoryCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })

        this.salesCategoryCount = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得指定銷售類別
    async FindSalesCategory () {
      try {
        const res = await FindSalesCategory({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        this.selectSalesCategory = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 更新銷售類別
    async updateSalesCategory () {
      try {
        const form = this.formData
        await UpdateSalesCategory({
          shopId: this.shop,
          id: this.selectRow.id,
          name: form.name,
          order: form.order,
          salesProducts: form.salesProducts,
          SalesGroupId: form.SalesGroupId || null,
        })

        await this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 刪除銷售類別
    async deleteSalesCategory () {
      try {
        await DeleteSalesCategory({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async getSalesGroupCount () {
      try {
        const res = await GetSalesGroupCount({
          shopId: this.shop,
        })
        return res.count
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async getSalesCategoryGroup () {
      const max = await this.getSalesGroupCount()
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        type: 'salesCategory',
      }
      const res = await getAllDataFromApi(max, GetSalesGroup, config)

      // const res = await GetSalesGroup({
      //   shopId: this.shop,
      //   type: 'salesCategory',
      // })

      this.salesGroupList = res
    },

  },
}
</script>

<style scoped lang="scss"></style>
